body {
    background-color: white;
    font-family: 'Merriweather', serif;
    margin: 0;
    opacity: 1;
    background-image:  linear-gradient(#e9e9e9 2px, transparent 2px), linear-gradient(90deg, #e9e9e9 2px, transparent 2px), linear-gradient(#e9e9e9 1px, transparent 1px), linear-gradient(90deg, #e9e9e9 1px, #ffffff 1px);
    background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
    background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
}

#page-container {
    display: flex;
    flex-direction: column;
    padding-top: 100px !important;
    min-height: 100vh;
}

#main-content {
    flex-grow: 1;
    width: 95%;
    max-width: 900px;
    display: block;
    margin: auto;
    text-align: center;
    background-color: white;
    padding: 5px;
    border: 1px solid rgb(141, 141, 141);
    box-shadow: 0 0 10px rgb(161, 161, 161);
}

#main-content p, #main-content ol, #main-content ul {
    text-align: left;
}

#main-content a {
    color: blue;
}

#search-bar-wrapper {
    margin-bottom: 20px;
}

#search-results {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 5px;
    column-gap: 10px;
    row-gap: 10px;
}

@media only screen and (max-width: 910px) {
    #search-results {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 600px) {
    #search-results {
        grid-template-columns: 1fr;
    }
}


#search-results img {
    max-height: 200px;
    max-width: 80%;
    margin: auto;
    padding: 10px;
}

.patent-drawing {
    border: 1px solid rgb(235, 235, 235);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20px 1fr 20px 20px;
    transition: 0.25s;
}

.patent-drawing:hover {
    border: 1px solid rgb(145, 145, 145);
}
.patent-drawing a {
    color: rgb(31, 31, 31) !important;
}
.patent-drawing:hover a {
    color: blue !important;
}

#search-bar {
    color: blue;
    font-family: 'Merriweather', serif;
    padding: 4px;
    border-radius: 0;
    border: 1px solid black;
    border-right: 0;
    font-weight: 500;
    width: 300px;
}

#search-btn { 
    font-family: 'Merriweather', serif;
    background-color: rgb(240, 240, 240);
    border-radius: 0;
    border: 1px solid rgb(87, 87, 87);
    padding: 4px 20px;
}

#search-btn:hover {
    background-color: rgb(212, 212, 212);
    cursor: pointer;
}

#search-btn img {
    display: none;
}

@media only screen and (max-width: 910px) {

    #search-bar-wrapper {
        display: grid;
        grid-template-columns: 1fr 60px;
        width: 95%;
        max-width: 400px;
        margin: auto;
        margin-bottom: 20px;
    }

    #search-bar { width: 100%; }

    #search-btn {
        padding: 0 10px;
        padding-top: 2px;
    }

    #search-btn img {
        display: unset;
        width: 30px;
    }

    #search-btn span { display: none; }
}