header {
    background-color: rgba(255, 255, 255, 0.808);
    backdrop-filter: blur(4px);
    display: grid;
    grid-template-columns: 420px 1fr 500px;
    position: fixed;
    top: 0;
    width: 100vw;
    border-bottom: 1px solid rgb(141, 141, 141);
}

header a {
    text-decoration: none;
    color: black;
}

header a:hover {
    color: blue;
}

#site-title, #site-subtitle {
    margin: 0;
    text-align: center;
}

#site-title {
    line-height: 70%;
    margin-top: 5px;
}

#site-nav {
    width: 500px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

#site-nav a {
    padding-top: 15px;
}

#site-nav a:hover {
    font-weight: bold;
}

#show-mob-menu {
    opacity: 0;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
#show-mob-menu:hover {
    cursor: pointer;
}

@media only screen and (min-width: 910px) {
    #mob-nav {
        display: none;
    }
}

@media only screen and (max-width: 910px) {
    header {
        grid-template-columns: 50px 1fr;
    }

    #mob-nav {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
        position: fixed;
        height: calc(100vh - 48px);
        width: 100vw;
        top: 48px;
        background-color: white;
        overflow-x: hidden;
        transition: 0.5s;
    }

    #mob-nav.hidden {
        transform: translateX(-100%);
    }

    #mob-nav a {
        text-decoration: none;
        text-align: center;
        font-size: 2em;
        padding-top: 1.25em;
        color: black;
    }
    #mob-nav a:hover {
        background-color: rgb(130, 184, 235);
    }

    #show-mob-menu {
        grid-column: 1;
        grid-row: 1;
        opacity: 1;
        font-size: 2em;
        padding-left: 10px;
    }
    #close-btn {
        font-size: 1.5em;
        line-height: 0.5em;
    }

    #site-titles {
        grid-column: 2;
        grid-row: 1;
    }
    #site-subtitle, #site-nav {
        display: none;
    }
    #site-title {
        line-height: 100%;
        padding-bottom: 10px;
        margin-right: 50px;
    }
}

.begone {
    display: none !important;
}