footer {
    margin-top: 20vh;
    padding-top: 10px;
    padding-bottom: 20vh;
    text-align: center;
    background-color: white;
}
#huggards {
    font-family: 'Merriweather', serif;
    letter-spacing: normal;
    text-align: center;
    font-weight: bold;
    font-size: 1em;
    width: 250px;
    color: rgb(99, 99, 99);
    border: 2px solid rgb(99, 99, 99);
    border-radius: 30px;
    padding: 5px;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: 0.5s;
}
#huggards:hover {
    color: black;
    border: 2px solid black;
}
.gh-link { cursor: pointer; }