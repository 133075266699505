body {
    background-color: white;
    font-family: 'Merriweather', serif;
    margin: 0;
    opacity: 1;
    background-image:  linear-gradient(#e9e9e9 2px, transparent 2px), linear-gradient(90deg, #e9e9e9 2px, transparent 2px), linear-gradient(#e9e9e9 1px, transparent 1px), linear-gradient(90deg, #e9e9e9 1px, #ffffff 1px);
    background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
    background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
}

#page-container {
    display: flex;
    flex-direction: column;
    padding-top: 100px !important;
    min-height: 100vh;
}

#main-content {
    flex-grow: 1;
    width: 95%;
    max-width: 900px;
    display: block;
    margin: auto;
    text-align: center;
    background-color: white;
    padding: 5px;
    border: 1px solid rgb(141, 141, 141);
    box-shadow: 0 0 10px rgb(161, 161, 161);
}

#main-content p, #main-content ol, #main-content ul {
    text-align: left;
}

#main-content a {
    color: blue;
}

#search-bar-wrapper {
    margin-bottom: 20px;
}

#search-results {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 5px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
}

@media only screen and (max-width: 910px) {
    #search-results {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 600px) {
    #search-results {
        grid-template-columns: 1fr;
    }
}


#search-results img {
    max-height: 200px;
    max-width: 80%;
    margin: auto;
    padding: 10px;
}

.patent-drawing {
    border: 1px solid rgb(235, 235, 235);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20px 1fr 20px 20px;
    transition: 0.25s;
}

.patent-drawing:hover {
    border: 1px solid rgb(145, 145, 145);
}
.patent-drawing a {
    color: rgb(31, 31, 31) !important;
}
.patent-drawing:hover a {
    color: blue !important;
}

#search-bar {
    color: blue;
    font-family: 'Merriweather', serif;
    padding: 4px;
    border-radius: 0;
    border: 1px solid black;
    border-right: 0;
    font-weight: 500;
    width: 300px;
}

#search-btn { 
    font-family: 'Merriweather', serif;
    background-color: rgb(240, 240, 240);
    border-radius: 0;
    border: 1px solid rgb(87, 87, 87);
    padding: 4px 20px;
}

#search-btn:hover {
    background-color: rgb(212, 212, 212);
    cursor: pointer;
}

#search-btn img {
    display: none;
}

@media only screen and (max-width: 910px) {

    #search-bar-wrapper {
        display: grid;
        grid-template-columns: 1fr 60px;
        width: 95%;
        max-width: 400px;
        margin: auto;
        margin-bottom: 20px;
    }

    #search-bar { width: 100%; }

    #search-btn {
        padding: 0 10px;
        padding-top: 2px;
    }

    #search-btn img {
        display: unset;
        width: 30px;
    }

    #search-btn span { display: none; }
}
header {
    background-color: rgba(255, 255, 255, 0.808);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    display: grid;
    grid-template-columns: 420px 1fr 500px;
    position: fixed;
    top: 0;
    width: 100vw;
    border-bottom: 1px solid rgb(141, 141, 141);
}

header a {
    text-decoration: none;
    color: black;
}

header a:hover {
    color: blue;
}

#site-title, #site-subtitle {
    margin: 0;
    text-align: center;
}

#site-title {
    line-height: 70%;
    margin-top: 5px;
}

#site-nav {
    width: 500px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

#site-nav a {
    padding-top: 15px;
}

#site-nav a:hover {
    font-weight: bold;
}

#show-mob-menu {
    opacity: 0;
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
#show-mob-menu:hover {
    cursor: pointer;
}

@media only screen and (min-width: 910px) {
    #mob-nav {
        display: none;
    }
}

@media only screen and (max-width: 910px) {
    header {
        grid-template-columns: 50px 1fr;
    }

    #mob-nav {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        row-gap: 10px;
        position: fixed;
        height: calc(100vh - 48px);
        width: 100vw;
        top: 48px;
        background-color: white;
        overflow-x: hidden;
        transition: 0.5s;
    }

    #mob-nav.hidden {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }

    #mob-nav a {
        text-decoration: none;
        text-align: center;
        font-size: 2em;
        padding-top: 1.25em;
        color: black;
    }
    #mob-nav a:hover {
        background-color: rgb(130, 184, 235);
    }

    #show-mob-menu {
        grid-column: 1;
        grid-row: 1;
        opacity: 1;
        font-size: 2em;
        padding-left: 10px;
    }
    #close-btn {
        font-size: 1.5em;
        line-height: 0.5em;
    }

    #site-titles {
        grid-column: 2;
        grid-row: 1;
    }
    #site-subtitle, #site-nav {
        display: none;
    }
    #site-title {
        line-height: 100%;
        padding-bottom: 10px;
        margin-right: 50px;
    }
}

.begone {
    display: none !important;
}
footer {
    margin-top: 20vh;
    padding-top: 10px;
    padding-bottom: 20vh;
    text-align: center;
    background-color: white;
}
#huggards {
    font-family: 'Merriweather', serif;
    letter-spacing: normal;
    text-align: center;
    font-weight: bold;
    font-size: 1em;
    width: 250px;
    color: rgb(99, 99, 99);
    border: 2px solid rgb(99, 99, 99);
    border-radius: 30px;
    padding: 5px;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: 0.5s;
}
#huggards:hover {
    color: black;
    border: 2px solid black;
}
.gh-link { cursor: pointer; }
